import React, {useState, useEffect} from "react"
import Layout from '../components/layout'

const ContactPage = (props) => {
  const [nameClass, setNameClass] = useState(null);
  const [name, setName] = useState(null);
  const [email, setEmail] = useState(null);
  const [emailClass, setEmailClass] = useState(null);
  const [question, setQuestion] = useState(null);
  const [questionClass, setQuestionClass] = useState(null);

  const [disabledSubmit, setDisabledSubmit] = useState(true)


  useEffect(() => {
    let disableSubmit = false;
    if(name && name !== '') {
      setNameClass('is-success');
    } else {
      setNameClass('is-danger');
      disableSubmit = true;
    }

    if(email && email!== '' && (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email) )) {
      setEmailClass('is-success');
    } else {
      setEmailClass('is-danger');
      disableSubmit = true;
    }

    
    if(question && question !== '') {
      setQuestionClass('is-success');
    } else {
      setQuestionClass('is-danger');
      disableSubmit = true;
    }
    setDisabledSubmit(disableSubmit);

    return undefined;

  }, [name, email, question]);

  let handleNameChange = (e) => {
    setName(e.target.value);
  }
  let handleEmailChange = (e) => {
    setEmail(e.target.value);
  }

  let handleQuestionChange = (e) => {
    setQuestion(e.target.value);
  }
  return (
    <Layout>
      <h1>Contact Us</h1>
      <div className="container">
        <form className="form" name="contact" method="POST" data-netlify="true" data-netlify-honeypot="bot-field">
          <input type="hidden" name="bot-field" />
          <input type="hidden" name="form-name" value="contact" />
          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <label className="label">Name</label>
            </div>
            <div className="field-body">
              <div className="field">
                <div className="control">
                  <input className={`input ${nameClass}`} type="text" placeholder="Your Name" name="Name" onChange={handleNameChange}/>
                </div>
                { nameClass === 'is-danger' && 
                  <p className="help is-danger">
                    This field is required
                  </p>
                }
              </div>
            </div>
          </div>


          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <label className="label">Email</label>
            </div>
            <div className="field-body">
              <div className="field">
                <div className="control">
                  <input className={`input ${emailClass}`} type="email" placeholder="someone@example.com" name="Email" onChange={handleEmailChange} />
                </div>
                { emailClass === 'is-danger' && 
                  <p className="help is-danger">
                    This field is required
                  </p>
                }
                
              </div>
            </div>
          </div>

          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <label className="label">Question</label>
            </div>
            <div className="field-body">
              <div className="field">
                <div className="control">
                  <textarea className={`textarea ${questionClass}`} placeholder="Explain how we can help you" name="Questions/Comments" onChange={handleQuestionChange}></textarea>
                </div>
                { questionClass === 'is-danger' && 
                  <p className="help is-danger">
                    This field is required
                  </p>
                }
              </div>
            </div>
          </div>
          <fieldset disabled={disabledSubmit}>
          <div className="field is-horizontal">
            <div className="field-label">
              {/* <!-- Left empty for spacing --> */}
            </div>
            <div className="field-body">
              <div className="field">
                <div className="control">
                  <button className={`button is-primary`} type="submit">
                    Send message
                  </button>
                </div>
              </div>
            </div>
          </div>
          </fieldset>
        </form>
      </div>
    </Layout>
  )

}

export default ContactPage;